import React from 'react';

export const Hanoi = {
    "name": "Hanoi",
    "link": "hanoi",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380406756_bace41c90b_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380406756_c197f74fb5_o.jpg",
    "caption": "The Capital of Thousand Years of Civilization",

    "description": "The capital city of Vietnam, Hanoi is a bustling and exciting metropolis. Founded more than 1000 years ago, the city is full of history and culture. From its old tiny roads packed with noise and movement from millions of scooters, bicycles and pedestrians creating a chaotic environment, to its many temples and monuments, you can surely expect an ancient and very dynamic environment. Hanoi is a city that embraces the past while trying to move on to the future.",

    "nrDays": <p>Despite being the country’s capital, the city is not so large, and 3 days will be good enough to explore all the main sites in Hanoi. If you have extra time to spare, you can take day trips to <a href="/travel-guides/asia/vietnam/ha-long-bay">Ha Long Bay</a> or <a href="/travel-guides/asia/vietnam/ninh-binh">Ninh Binh</a>, or just explore the city in a more calm and relaxed manner, enjoying the food and scenery. There will always be something in Hanoi to keep you busy.
              </p>,

    "whenVisit": <p>Your experience will depend on the weather and the number of tourists around. Hanoi has basically two seasons. A hot and humid summer and a cool, dry winter. The hot and wet weather is from May to October, and the period with the most
                rainfall and highest temperatures is from July to September. Unless you like to go sightseeing in the rain, these months are not good to see the city. The high season occurs between Summer and Winter. It is the best time to visit the city in terms of weather since the heat is not yet uncomfortable. The downside is the increase in tourists and the prices.<br/><br/>
            Personally, we recommend the months from <b>February to April</b>. When you will find good weather and the majority of the tourists haven't yet arrived.
                 </p>,

    "mobility": <p>
                With so much to see in Hanoi, one of your concerns will be how to get from one attraction to another. Since the main city’s attractions are relatively close together, the simple way to get around Hanoi is walking.
                <br/><br/>
                There isn’t so much in the way of public transport in Hanoi. And taxi drivers are also known for scamming tourists, so try to avoid them if possible.
                <br/><br/>
                Getting a bike is a solution to move between different points, but only if you are really comfortable at it! The traffic in the city center is not easy.
                The best option to get around, as in the other Vietnam and Southeast Asia cities, is using <a href="https://www.grab.com/" target="_blank" rel="noopener noreferrer">Grab</a>! You can be sure that you will not be ripped off, and it is an easy service if have used ride sharing apps before.
                <br/><br/>
                Finally, to get from the airport to the city center you have two options. You can again use Grab, or take a bus, that is a cheaper but slower option. You can take a bus from the airport to Kim Ma bus station (Bus 7) or to Long Bien bus station (Bus 17). It will take around an hour and it will cost 9000 VND which <b>must(!)</b> be paid in cash to the driver. Some travel companies, namely Vietnam Airlines, Jetstar Asia or VietJet, also provide a shuttle bus service.
                </p>,

    "safety": <p>Hanoi is an extremely safe city to go, and you will feel comfortable there at any time of the day. But like in any other crowded place, you should be aware the pickpocketing and bag-snatching. However, this type of crime is not so usual there.
            <br/><br/>
            There are also some scams you should be aware of when you go to the city.
            One of them is the shoe shining, mostly in the Old Quarter. You will find some people looking around for a not-so-perfect pair of shine shoes, they will offer to glue it, and will ask you for money in the end. So, do not accept this kind of “service".
            Another one is the fruit selling, where a lady will come to you and put a basket of fruit to take a picture with, which will not be free. It is not a big trouble, but we think it’s not good to encourage this behavior.
            And the main one is the extras on massages. This could end up being a little expensive if you do not know what you are doing. The massages are offered at a price, but along it a lot of extras, like tea or facial, are added on the price. In the end, it turns uncomfortable not to pay for something that you already had.<br/>
            <br/>
            As you can see, the type of crime is not something to worry about, and you can have a great time there.
            </p>,

    "itinerary": {
        "description": "As suggested before, we recommend at least 3 days to see the city. It is enough time to see some of the hidden places and to embrace the beauty and the atmosphere of the city. For that we will suggest a 3-day itinerary where you can visit all the hot spots. Of course, it’s only a suggestion and it is possible to adjust as you wish.",
        "days": [
            {
                "description":
                    <p>
                        For the first day, we started with a visit to the Lake Ho Hoan Kiem and all the surrounding area. It is a nice and quite place to start your day. After that, we went to the Hanoi Old Quarter in the north and west areas from the lake. A must visit place here is the Hanoi Train Street. Make sure to be there when the train passes, because it definitely is a picture that you want to get. For lunch, all Vietnamese will suggest Bún chả, and you can find some suggestions to eat this in our <a href="#food">Food and Drinks</a> section. Following the old quarter area you can visit the Temple of Literature, Hoa Lo Prison Memorial, Woman Museum and the French Quarter. Of course, it is difficult to see all those places in an afternoon, so we suggest to leave some of it for next morning. To finish your day, you should spend the night around the old quarter area, which has a very crowded night life. There will be plenty of restaurants and bars here, and if you are an Harry Potter fan you must go to a themed coffee shop called “Always”.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50398566083_f52d688c52_o.jpg",
                "caption": "Famous Hanoi Train Street."
            },
            {
                "description": <p>
                As we said on the previous day, during the morning you should continue your search for the museums. After that, a street food walking tour is a nice choice. You will learn a lot about the national cuisine and taste some typical food. During the rest of afternoon, you can take a little “free time” to look to the flower and local markets to embrace the city and people there. If you are not a fan of this type of markets, you can also opt for a cooking class, a very unique experience for the tourists. In the evening you should try watching the Water Puppet Show, a unique Vietnamese tradition and a show that you will remember. For this day we suggest to dinner at Chả Cá Thảng Long.
                </p>,
                "image": "https://live.staticflickr.com/65535/50399417142_d1e3835650_o.jpg",
                "caption": "Nightlife on the streets of Hanoi."
            },
            {
                "description": <p>For the third day, we recommend visiting the area a little further from the city centre. Start on the Tran Quoc Pagoda, which sits to the West Lake. This area has a lot of temples and shrines that we thought to be very interesting. Following the area, the next spot is the Ho Chi Minh Mausoleum, and not far from it you will find the museum. For the afternoon we recommend a visit to the Vietnam Military History Museum, and within a short walk you will find the Thăng Long Imperial Citadel, the only UNESCO world heritage site in Hanoi.</p>,
                "image": "https://live.staticflickr.com/65535/50399257376_1b439f67a0_o.jpg",
                "caption": "Thăng Long Imperial Citadel, Hanoi."
            },
        ]
    },

    "highlights": [
        {
            "name": "Lake Ho Hoan Kiem",
            "description": "It is not easy to miss this large lake in the center of Hanoi, near the city’s Old Quarter. It's a popular meeting spot both for tourists and locals. You will see people exercising, practicing tai chi, and even local students trying to practice their English. The Turtle Tower is the signature sight of the lake, and you can find other landmarks here, like the Húc Bridge and the Temple of the Jade Mountain. Hoan Kiem Lake equally lights up at night. It is a picturesque spot to walk around and appreciate the lights – it takes about half an hour to walk around the lake in its totality.",
            "image": "https://live.staticflickr.com/65535/50399416957_b45e4fa8a7_o.jpg"
        },
        {
            "name": "Old Quarter",
            "description": "Hanoi’s bustling Old Quarter is fascinating for a walk around in the daytime – but it certainly comes alive at night. It is worth moving around slowly, to take it all in. There are street food sellers, market vendors and a whole load of motorbikes. You will also find plenty of bars and restaurants here. Mixed among all this you will spot temples, gates, and halls dating back centuries. Two sights that show you wildly distinct edges of Hanoi are the Gothic Saint Joseph Cathedral and the well-preserved, traditional Ancient House. If you are around during Vietnamese Independence Day or Ho Chi Minh’s Birthday you will be pleasured with nice events.",
            "image": "https://live.staticflickr.com/65535/50399257291_46027a33c4_o.jpg"
        },
        {
            "name": "French Quarter",
            "description": "What you will not miss in the French Quarter are the most famous colonial buildings, including the opera house. Neighboring stands the train street, where the rails leading up to Hanoi’s station go straight through a residential street. It is a chilly spot to check out – the train hurtles through at around 3pm and 7pm.",
            "image": "https://live.staticflickr.com/65535/50398565913_965eb650c8_o.jpg"
        },
        {
            "name": "Temple of Literature",
            "description": "One of the most magnificent places to visit in Vietnam, the Temple of Literature was built as a Confucian university in 1070. It has been since used as a place of spiritual education and has a lot of conventional features to be amazed. It is one of the most historically significant places to visit in Hanoi, so take your time to explore and take it all in.",
            "image": "https://live.staticflickr.com/65535/50399257271_79ce6ca737_o.jpg"
        },
        {
            "name": "Hoa Lo Prison Memorial",
            "description": "It is not possible to ignore the past of Vietnam on a visit to Hanoi, and one place which most visitors get to see is the Hoa Lo Prison. Now a memorial, this prison is best known for holding US prisoners of war which earned it the nickname the “Hanoi Hilton”. But the prison was in use before that, housing Vietnamese political prisoners during the days of French colonialism. Visit inside and you’ll be taken through the history of the prison as seen from a Vietnamese nationalist perspective. Walking through the prison will give you some insight into the life of prisoners there, as well as how Vietnam’s history is presented today.",
            "image": "https://live.staticflickr.com/65535/50399416837_e4a1798492_o.jpg"
        },
        {
            "name": "Hanoi Train Street",
            "description": "One of the most unusual and scenic spots in Hanoi is the city’s so-called “train street”. Located in the Old Quarter, the train tracks squeeze across some of the city’s narrower streets, with the doors of people homes opening out onto them. The trains hurtles here twice a day, around 3pm and 7pm, so be sure that you verify the timing so not miss the “show”. Sill, even if you visit when the train is not around, the neighborhood offers a fascinating insight of how the living in Hanoi is.",
            "image": "https://live.staticflickr.com/65535/50399257201_08504a0bf4_o.jpg"
        },
        {
            "name": "Women’s Museum",
            "description": "This place can give you a different look about the culture in this country. It concentrates on the important role of women in Vietnamese society, both past and present. A lot of these women aren’t from Hanoi, but they are living here, away from their kids, since they need to make money. It’s a strong perspective and for sure values 2 hours of your time.",
            "image": "https://live.staticflickr.com/65535/50399416812_64f36d8ef4_o.jpg"
        },
        {
            "name": "Tran Quoc Pagoda",
            "description": "One of the city’s most iconic shrines is the Tran Quoc Pagoda. Sitting out by West Lake, it is the oldest Buddhist shrine in Hanoi through a history that stretches back to the 6th century. To get to the shrine, you must first cross a causeway which links the islands with the shores of the lake. Near the West Lake, you will find the Lotte Center, which is the tallest building in North Vietnam, and where you can find a great viewpoint for whole the city. Also, you can reach the Quan Thanh Temple in a swan boat across the lake.",
            "image": "https://live.staticflickr.com/65535/50399416777_5f4d3ebfed_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Vietnamese cuisine differs region by region. Each city, sometimes, even each village has its own list of distinctive local specialties. Hanoi is no exception, and being pretty famous for its cuisine, many of the popular Vietnamese dishes are originated here. The Vietnamese noodle soup Pho is one of the most iconic northern Vietnamese food.
            <br/><br/>
            Hanoi has a very big scene with street food, and you cannot miss experiencing some delicious local dishes. Just choose one of the many restaurant with plastic chairs and tables across the streets and ask for pho, com ga, and bun cha. However, if you prefer experience everything in a more “safe” way, and at the same time have someone explaining what you’re eating and why it’s special, do a street food walking tour.
            <br/><br/>
            If you prefer to eat in a restaurant instead of the street you will also find a huge variety of possibilities. There are also tonnes of great spots to eat around Tay Ho. I heartily recommend the following:
            </p>,
        "restaurants": [
            {
                "name": "Bún Chả Hương Liên",
                "description": "This place is famous for its Bún Chả accompanied wth “Nuem Cua Be”, a crab spring rolls.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50399257546_1c27c7ede3_o.jpg"
            },
            {
                "name": "Hùng Lẩu",
                "description": "A typical Vietnamese restaurant, full of locals, and you will probably need to use a translator to make your request. Also famous for its Bún Chả.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50399417272_54d6f979bb_o.jpg"
            },
            {
                "name": "Chả Cá Thảng Long",
                "description": "Surrounded by the aroma of fresh herbs, here you can taste a buttery grilled fish, perfectly seasoned with turmeric and noodles soaked in fish sauce and combined with fresh herbs.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50398566168_8383e8b8fe_o.jpg"
            },
            {
                "name": "Hidden Gem Coffee",
                "description": "This cafe has a peculiar decoration, maybe a bit intimidating at first. Rest assured, this is one of the most memorable places in Hanoi, where you must try the egg coffee.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50399257481_2737afb38a_o.jpg"
            },
            {
                "name": "Handi",
                "description": "This is a fantastic Pakistani restaurant that serves up some of the best hummus you will ever eat.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50398566113_41e0b38e15_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Since Hanoi is a common destination among tourists of all kinds, you shouldn’t have any problem to find a place to stay that works for you. It is always important to consider what part of the city you should look. A central place, close to attractions, restaurants and other facilities are aspects that you need to take into account. Typically, tourists focus on Hanoi’s Old Quarter, though more luxurious places are situated in the French Quarter.",
        "budget": [
            {
                "name": "Old Quarter View Hanoi Hostel",
                "description": "If you are looking for a cheap place, like a Hostel, the Old Quarter view Hanoi Hostel is a nice place. It has sturdy, good quality bunk beds and spacious dorms. Also, it is a great place to meet people.",
                "image": "https://live.staticflickr.com/65535/50398566338_d7c578b573_o.jpg",
                "link": "/"
            },
            {
                "name": "The Dragon Hotel",
                "description": "Located on the shores of Tay On. It’s a good place if you want to escape the crowds of tourists, however, it is a little bit dislocated.",
                "image": "https://live.staticflickr.com/65535/50399257651_8501008d91_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Sunline Paon Hotel and Spa",
                "description": "Has a fantastic location, right in the middle of the old quarter.",
                "image": "https://live.staticflickr.com/65535/50398566303_cddcf3c9b6_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Apricot Hotel",
                "description": "If you are searching for a luxurious spot to stay, you will not be disappointed with the Apricot Hotel. It has a great location, with elegant rooms, a rooftop bar and swimming pool with an amazing view of the lake.",
                "image": "https://live.staticflickr.com/65535/50398566253_2c7961e06b_o.jpg",
                "link": "/"
            }
        ]
    }
}